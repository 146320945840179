var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.search.view > 0)?_c('v-data-iterator',{staticClass:"white",attrs:{"items":_vm.products,"items-per-page":_vm.itemsPerPage,"footer-props":{
    itemsPerPageOptions: _vm.itemsPerPageOptions,
    disableItemsPerPage: false,
    itemsPerPageText: '',
    // showFirstLastPage: true,
    pagination: _vm.$store.state.search.pagination,
  },"sort-by":_vm.$store.state.search.sortBy,"sort-desc":_vm.$store.state.search.sortDesc,"search":_vm.$store.state.search.query,"loading":_vm.$store.state.search.loading,"page":_vm.currentPage,"no-data-text":"","loading-text":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.$store.state.search, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.$store.state.search, "sortBy", $event)},function($event){_vm.currentPage = 1}],"update:page":function($event){_vm.currentPage=$event},"pagination":_vm.paginationUpdated,"update:sort-desc":function($event){_vm.currentPage = 1}},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
  var pageStart = ref.pageStart;
  var pageStop = ref.pageStop;
  var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" of "+_vm._s(itemsLength)+" ")]}},{key:"default",fn:function(ref){
  var items = ref.items;
return [_c('v-row',{staticClass:"mx-1 mt-5"},_vm._l((items),function(item,i){return _c('v-col',{key:i,staticClass:"text-center",attrs:{"cols":_vm.viewOptions.cols}},[_c('a',{attrs:{"href":item.trackingUrl,"target":"_blank"}},[_c('v-img',{staticClass:"my-1 mx-auto",attrs:{"lazy":"","contain":"","title":item.title,"max-width":_vm.viewOptions.imgWidth,"height":_vm.viewOptions.imgHeight,"max-height":_vm.viewOptions.imgHeight,"src":("https://res.cloudinary.com/demo/image/fetch/e_trim/w_" + (_vm.viewOptions.imgWidth) + ",h_" + (_vm.viewOptions.imgHeight) + ",c_fit/" + (item.imageUrl))}})],1),(_vm.$store.state.search.view > 1)?_c('router-link',{staticClass:"text-subtitle-1 overflow-hidden mt-5 font-weight-bold text-capitalize\n            black--text",attrs:{"to":{ name: 'Collection', params: { slug: item.brand.slugify() } }}},[_vm._v(" "+_vm._s(item.brand.toTitleCase())+" ")]):_vm._e(),(_vm.$store.state.search.view > 2)?_c('div',{staticClass:"text-subtitle-2 overflow-hidden mt-3"},[_vm._v(" "+_vm._s(item.title.toTitleCase())+" ")]):_vm._e(),(_vm.$store.state.search.view > 0)?_c('div',{staticClass:"text-subtitle-1 my-3"},[(item.priceOrig)?_c('span',{staticClass:"red--text"},[_vm._v(" $"+_vm._s(item.price)+" ")]):_vm._e(),(item.priceOrig)?_c('strike',[_vm._v(" $"+_vm._s(item.priceOrig)+" ")]):_c('span',[_vm._v(" $"+_vm._s(item.price)+" ")])],1):_vm._e()],1)}),1)]}}],null,false,4035174549)},[_vm._v(" > ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }