import { Auth } from 'aws-amplify';
import * as S3 from 'aws-sdk/clients/s3';
import slugify from 'slugify';

export const getProducts = async (store, category, brand, mode) => {
  if (!store || store === '-') { // no store = no S3 file to query
    console.log('getProducts', 'no store');
    return [];
  }

  let credentials = {};
  try {
    credentials = await Auth.currentCredentials();
    // console.log('user', credentials);
  } catch (e) {
    // await Auth.signIn('guest', 'password'); // guest user
    // credentials = await Auth.currentCredentials();
    // console.log('guest', credentials);
    console.log(e);
  }

  const s3 = new S3({
    // region: 'us-east-1',
    credentials: Auth.essentialCredentials(credentials),
  });

  let whereClause = '';
  if (brand && brand !== '-') {
    whereClause = `WHERE brand = '${brand.replace(/'/g, '\'\'')}'`;
  }
  if (category && category !== '-') {
    if (whereClause) {
      whereClause += ` AND category = '${category.replace(/'/g, '\'\'')}'`;
    } else {
      whereClause += ` WHERE category = '${category.replace(/'/g, '\'\'')}'`;
    }
  }

  const modeIndex = mode || 0;
  const modes = ['', '-new', '-sale'];
  const params = {
    Bucket: 'shopscoutstorage104233-prod', // change bucket region depending on country
    Key: `feeds/${slugify(store, { lower: true })}${modes[modeIndex]}.parquet`,
    ExpressionType: 'SQL',
    Expression: `SELECT title, brand, imageUrl, price, category, trackingUrl, priceOrig, createdAt FROM S3Object ${whereClause} LIMIT 500`,
    InputSerialization: { Parquet: {} },
    OutputSerialization: { JSON: { RecordDelimiter: ',\n' } },
  };

  let products = [];
  try {
    const selectObjectContentResponse = await s3.selectObjectContent(params).promise();

    let recordsJSON = '[';
    // eslint-disable-next-line
    for await (const event of selectObjectContentResponse.Payload) {
      if (event.Records) {
        recordsJSON += event.Records.Payload.toString();
      }
    }
    recordsJSON = recordsJSON.slice(0, -2); // remove trailing comma for last item
    recordsJSON += ']';

    try {
      products = await JSON.parse(recordsJSON);
      for (let i = 0; i < products.length; i += 1) {
        products[i].discount = products[i].priceOrig
          ? 100 - Math.round((products[i].price / products[i].priceOrig) * 100) : 0;
      }
    } catch (e) {
      console.log(e);
    }
  } catch (e) {
    console.log(e);
  }
  return products;
};

export default {};
