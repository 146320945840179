<template>
  <v-container>
    <v-row class="mx-5 my-3">
      <v-col cols="12">
        <h1 class="text-h3">Welcome</h1>
      </v-col>
      <v-col cols="12">
        Keep track of all <strong>sales and new arrivals</strong>
        from the stores and brands you follow.
        Type a brand, store, or category in the search box above.
      </v-col>
    </v-row>
    <v-row class="mx-5 my-3" v-if="$store.state.userDetails">
      <v-col cols="12" v-for="listType in ['brands','stores']" :key="listType">
        <v-row align="center" :key="`heading-${listType}`">
          <v-col class="text-overline">
            Recent {{ listType === 'categorys' ? 'Collections' : listType.toTitleCase() }}
          </v-col>
        </v-row>
        <v-row :key="`no-data-${listType}`"
          v-if="listType in $store.state.userDetails &&
            $store.state.userDetails[listType].length == 0">
          <v-col class="mx-4">
            <small class="font-italic font-weight-thin">
              {{ listType.toTitleCase() }} you visit will show here
            </small>
          </v-col>
        </v-row>
        <v-row v-if="listType in $store.state.userDetails">
          <v-col v-for="item in $store.state.userDetails[listType].slice(0, 20).sort()"
            :key="`${listType}-${item}`" cols="12" sm="6" md="4" lg="3" xl="2">
            <v-card>
              <v-btn text block class="px-5 py-8 ma-0 justify-center black white--text"
                :to="{
                  name: 'Collection',
                  params: { slug: item.slugify() },
                }">
                {{ item.toTitleCase() }}
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <CollectionsDataTable :collections="$store.state.featuredBrands" targetField="title"
      key="featuredBrandsDataTable" /> -->
    <v-divider />
    <v-row class="mx-5 my-3">
      <v-col cols="12">
        <v-row align="center">
          <v-col class="text-overline">
            Featured brands
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col
            v-for="item in $store.state.featuredBrands"
            :key="`featuredBrand-${item.id}`" cols="12" sm="6" md="4" lg="3" xl="2">
            <v-card>
              <v-btn text block
                class="px-5 py-8 ma-0 justify-center black white--text overflow-hidden"
                :to="{
                  name: 'Collection',
                  params: { slug: item.title.slugify(), type: 'brand' },
                }">
                {{ item.title.toTitleCase() }}
                <!-- </v-badge> -->
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import CollectionsDataTable from '@/components/CollectionsDataTable.vue';

export default {
  // components: {
  //   CollectionsDataTable,
  // },
  data() {
    return {
      chipIcons: {
        store: 'mdi-cart-outline',
        collection: 'mdi-tag-multiple-outline',
        product: 'mdi-circle-multiple-outline',
        // category: 'mdi-shape-outline',
        category: 'mdi-tag-multiple-outline',
        brand: 'mdi-shield-star-outline',
      },
    };
  },
};
</script>
