/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listProducts = /* GraphQL */ `
  query ListProducts($store: String, $category: String, $brand: String) {
    listProducts(store: $store, category: $category, brand: $brand)
  }
`;
export const getStore = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      owner
      createdAt
      updatedAt
      title
      description
      slug
      country
      feedUrl
      status
      tags
      settings
    }
  }
`;
export const listStores = /* GraphQL */ `
  query ListStores(
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        createdAt
        updatedAt
        title
        description
        slug
        country
        feedUrl
        status
        tags
        settings
      }
      nextToken
    }
  }
`;
export const storesBySlug = /* GraphQL */ `
  query StoresBySlug(
    $slug: String
    $country: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    storesBySlug(
      slug: $slug
      country: $country
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        title
        description
        slug
        country
        feedUrl
        status
        tags
        settings
      }
      nextToken
    }
  }
`;
export const getCollection = /* GraphQL */ `
  query GetCollection($store: String!, $brand: String!, $category: String!) {
    getCollection(store: $store, brand: $brand, category: $category) {
      id
      owner
      createdAt
      updatedAt
      processedAt
      title
      slug
      store
      brand
      category
      total
      totalSale
      totalNew
      discount
      priceFrom
      priceTo
      status
      cardinality
      type
      metadata
      details
    }
  }
`;
export const listCollections = /* GraphQL */ `
  query ListCollections(
    $store: String
    $brandCategory: ModelCollectionPrimaryCompositeKeyConditionInput
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCollections(
      store: $store
      brandCategory: $brandCategory
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        processedAt
        title
        slug
        store
        brand
        category
        total
        totalSale
        totalNew
        discount
        priceFrom
        priceTo
        status
        cardinality
        type
        metadata
        details
      }
      nextToken
    }
  }
`;
export const brandCardinalityStatus = /* GraphQL */ `
  query BrandCardinalityStatus(
    $brand: String
    $cardinalityStatus: ModelCollectionBrandCardinalityStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    brandCardinalityStatus(
      brand: $brand
      cardinalityStatus: $cardinalityStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        processedAt
        title
        slug
        store
        brand
        category
        total
        totalSale
        totalNew
        discount
        priceFrom
        priceTo
        status
        cardinality
        type
        metadata
        details
      }
      nextToken
    }
  }
`;
export const brandCardinalityCategoryStatus = /* GraphQL */ `
  query BrandCardinalityCategoryStatus(
    $brand: String
    $cardinalityCategoryStatus: ModelCollectionBrandCardinalityCategoryStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    brandCardinalityCategoryStatus(
      brand: $brand
      cardinalityCategoryStatus: $cardinalityCategoryStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        processedAt
        title
        slug
        store
        brand
        category
        total
        totalSale
        totalNew
        discount
        priceFrom
        priceTo
        status
        cardinality
        type
        metadata
        details
      }
      nextToken
    }
  }
`;
export const brandCardinalityStoreStatus = /* GraphQL */ `
  query BrandCardinalityStoreStatus(
    $brand: String
    $cardinalityStoreStatus: ModelCollectionBrandCardinalityStoreStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    brandCardinalityStoreStatus(
      brand: $brand
      cardinalityStoreStatus: $cardinalityStoreStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        processedAt
        title
        slug
        store
        brand
        category
        total
        totalSale
        totalNew
        discount
        priceFrom
        priceTo
        status
        cardinality
        type
        metadata
        details
      }
      nextToken
    }
  }
`;
export const categoryCardinalityStatus = /* GraphQL */ `
  query CategoryCardinalityStatus(
    $category: String
    $cardinalityStatus: ModelCollectionCategoryCardinalityStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryCardinalityStatus(
      category: $category
      cardinalityStatus: $cardinalityStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        processedAt
        title
        slug
        store
        brand
        category
        total
        totalSale
        totalNew
        discount
        priceFrom
        priceTo
        status
        cardinality
        type
        metadata
        details
      }
      nextToken
    }
  }
`;
export const categoryCardinalityBrandStatus = /* GraphQL */ `
  query CategoryCardinalityBrandStatus(
    $category: String
    $cardinalityBrandStatus: ModelCollectionCategoryCardinalityBrandStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryCardinalityBrandStatus(
      category: $category
      cardinalityBrandStatus: $cardinalityBrandStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        processedAt
        title
        slug
        store
        brand
        category
        total
        totalSale
        totalNew
        discount
        priceFrom
        priceTo
        status
        cardinality
        type
        metadata
        details
      }
      nextToken
    }
  }
`;
export const categoryCardinalityStoreStatus = /* GraphQL */ `
  query CategoryCardinalityStoreStatus(
    $category: String
    $cardinalityStoreStatus: ModelCollectionCategoryCardinalityStoreStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryCardinalityStoreStatus(
      category: $category
      cardinalityStoreStatus: $cardinalityStoreStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        processedAt
        title
        slug
        store
        brand
        category
        total
        totalSale
        totalNew
        discount
        priceFrom
        priceTo
        status
        cardinality
        type
        metadata
        details
      }
      nextToken
    }
  }
`;
export const storeCardinalityStatus = /* GraphQL */ `
  query StoreCardinalityStatus(
    $store: String
    $cardinalityStatus: ModelCollectionStoreCardinalityStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    storeCardinalityStatus(
      store: $store
      cardinalityStatus: $cardinalityStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        processedAt
        title
        slug
        store
        brand
        category
        total
        totalSale
        totalNew
        discount
        priceFrom
        priceTo
        status
        cardinality
        type
        metadata
        details
      }
      nextToken
    }
  }
`;
export const storeCardinalityBrandStatus = /* GraphQL */ `
  query StoreCardinalityBrandStatus(
    $store: String
    $cardinalityBrandStatus: ModelCollectionStoreCardinalityBrandStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    storeCardinalityBrandStatus(
      store: $store
      cardinalityBrandStatus: $cardinalityBrandStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        processedAt
        title
        slug
        store
        brand
        category
        total
        totalSale
        totalNew
        discount
        priceFrom
        priceTo
        status
        cardinality
        type
        metadata
        details
      }
      nextToken
    }
  }
`;
export const storeCardinalityCategoryStatus = /* GraphQL */ `
  query StoreCardinalityCategoryStatus(
    $store: String
    $cardinalityCategoryStatus: ModelCollectionStoreCardinalityCategoryStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    storeCardinalityCategoryStatus(
      store: $store
      cardinalityCategoryStatus: $cardinalityCategoryStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        processedAt
        title
        slug
        store
        brand
        category
        total
        totalSale
        totalNew
        discount
        priceFrom
        priceTo
        status
        cardinality
        type
        metadata
        details
      }
      nextToken
    }
  }
`;
export const collectionsBySlugType = /* GraphQL */ `
  query CollectionsBySlugType(
    $slug: String
    $type: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectionsBySlugType(
      slug: $slug
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        processedAt
        title
        slug
        store
        brand
        category
        total
        totalSale
        totalNew
        discount
        priceFrom
        priceTo
        status
        cardinality
        type
        metadata
        details
      }
      nextToken
    }
  }
`;
export const collectionsByCardinalityTitle = /* GraphQL */ `
  query CollectionsByCardinalityTitle(
    $cardinality: Int
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectionsByCardinalityTitle(
      cardinality: $cardinality
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        processedAt
        title
        slug
        store
        brand
        category
        total
        totalSale
        totalNew
        discount
        priceFrom
        priceTo
        status
        cardinality
        type
        metadata
        details
      }
      nextToken
    }
  }
`;
export const collectionsByStoreProcessedAt = /* GraphQL */ `
  query CollectionsByStoreProcessedAt(
    $store: String
    $processedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectionsByStoreProcessedAt(
      store: $store
      processedAt: $processedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        processedAt
        title
        slug
        store
        brand
        category
        total
        totalSale
        totalNew
        discount
        priceFrom
        priceTo
        status
        cardinality
        type
        metadata
        details
      }
      nextToken
    }
  }
`;
