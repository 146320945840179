<template>
  <v-card flat>
    <v-row>
      <v-col cols="2">
        <!-- <v-btn-toggle mandatory dense class="mx-3 my-2"
          v-model="$store.state.search.mode">
          <v-btn v-for="mode in modes" :key="mode" outlined>{{ mode }}</v-btn>
        </v-btn-toggle> -->
        <v-treeview :items="categories" v-model="selectedCategories" open-on-click
          selection-type="all" return-object activatable :active.sync="activeCategory"
          class="mt-5" v-if="categories.length > 0" />
          <!-- :search="$store.state.search.query" -->
        <!-- <v-autocomplete placeholder="Brand" dense solo rounded class="mx-3 mt-9"
          :items="brands.map((x) => ({ value: x.brand, text: x.brand.toTitleCase() }))"
          v-if="brands.length > 0" clearable v-model="$store.state.search.brand" /> -->
      </v-col>
      <v-col cols="10">
        <ProductsTable :collection="$store.state.collection"
          :items-per-page="48" :products-prop="products" />
      </v-col>
      <!-- <v-col cols="12" class="grey lighten-5">
        <v-list>
          <v-list-item>Collection: {{ collection }}</v-list-item>
          <v-list-item>Mode: {{ modes[$store.state.search.mode] }}</v-list-item>
          <v-list-item>Selected: {{ selectedCategories }}</v-list-item>
          <v-list-item>Active: {{ activeCategory }}</v-list-item>
        </v-list>
      </v-col> -->
    </v-row>
  </v-card>
</template>

<script>
import ProductsTable from '@/components/ProductsTable.vue';
import { categoriesByCollection, getCategories, getBrands } from '@/helpers/api';
import { buildTreeview } from '@/helpers/vuetify';
import { getProducts } from '@/helpers/s3';

export default {
  props: ['collection', 'categoryCollectionsProp'],
  components: { ProductsTable },
  async created() {
    this.init();
  },
  methods: {
    async init() {
      this.categoryCollections = this.categoryCollectionsProp
        && this.categoryCollectionsProp.length > 0
        ? this.categoryCollectionsProp : await categoriesByCollection(this.collection);
      this.products = await getProducts(
        this.collection.store,
        '-',
        this.collection.brand,
        this.$store.state.search.mode,
      );
      this.$store.state.brands = await getBrands(
        this.$store.state.collection.store,
        this.$store.state.search.category,
        this.$store.state.search.mode,
      );
    },
  },
  data() {
    return {
      categoryCollections: [],
      modes: ['All', 'New', 'Sale'],
      selectedCategories: [],
      activeCategory: [],
      products: [],
    };
  },
  computed: {
    categories() {
      const base = { name: 'BASE', children: [] };
      this.categoryCollections.forEach((collection) => {
        buildTreeview(
          base,
          collection.category.split('>').map((x) => x.trim().toTitleCase()),
          0,
        );
      });
      return base.children;
    },
  },
  watch: {
    async activeCategory() {
      const category = this.activeCategory.length > 0
        ? this.activeCategory[0].id.toLowerCase() : null;

      this.$store.state.search.category = category;
    },
    categoryCollectionsProp() {
      this.init();
    },
    '$store.state.search.mode': async function () {
      // update products
      this.products = await getProducts(
        this.$store.state.collection.store,
        '-',
        this.$store.state.collection.brand,
        this.$store.state.search.mode,
      );
      // update categories
      this.categoryCollections = await getCategories(
        this.$store.state.collection.store,
        this.$store.state.collection.brand,
        this.$store.state.search.mode,
      );
    },
    '$store.state.search.category': async function () {
      // update products
      this.products = await getProducts(
        this.$store.state.collection.store,
        this.$store.state.search.category,
        this.$store.state.collection.brand,
        this.$store.state.search.mode,
      );
      // update brands
      this.$store.state.brands = await getBrands(
        this.$store.state.collection.store,
        this.$store.state.search.category,
        this.$store.state.search.mode,
      );
    },
    '$store.state.search.brand': async function () {
      const brand = this.$store.state.search.brand ? this.$store.state.search.brand : '-';
      const { collection } = this;
      collection.brand = brand; // THIS IS WEIRD
      this.$store.commit('setCollection', collection);
      this.products = await getProducts(
        this.$store.state.collection.store,
        this.$store.state.collection.category,
        this.$store.state.collection.brand,
        this.$store.state.search.mode,
      );
      this.categoryCollections = await getCategories(collection.store, collection.brand);
    },
    '$store.state.collection': () => {
      console.log('$store.state.collection');
    },
  },
};
</script>
