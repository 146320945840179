<template>
  <div>
    <Store :key="store.id" v-for="store in stores" :store="store" />
  </div>
</template>

<script>
import Store from '@/components/Store.vue';

export default {
  props: ['stores'],
  components: {
    Store,
  },
};
</script>
