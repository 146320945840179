<template>
  <div>
    <v-data-iterator
      :items="products"
      v-if="$store.state.search.view > 0"
      :items-per-page="itemsPerPage"
      class="white pb-10"
      :footer-props="{ itemsPerPageOptions }"
      :sort-by.sync="$store.state.search.sortBy"
      :sort-desc="$store.state.search.sortDesc"
      :search="$store.state.search.query"
      :loading="$store.state.search.loading"
      :page.sync="$store.state.search.currentPage"
      @pagination="paginationUpdated"
      no-data-text=""
      loading-text=""
      @update:sort-by="$store.state.search.currentPage = 1"
      @update:sort-desc="$store.state.search.currentPage = 1"
      >
    >
      <template v-slot:footer.page-text="{  pageStart, pageStop, itemsLength }">
        {{ pageStart }}
        -
        {{ pageStop }}
        of
        {{ itemsLength }}
        <v-btn color="primary" small @click="increaseItemsPerPage()" v-if="pageStop < itemsLength"
          class="ml-9">
          Load more
        </v-btn>
      </template>
      <template v-slot:default="{ items }">
        <v-row class="mx-1 mt-5">
          <v-col :cols="viewOptions.cols" v-for="(item, i) in items" :key="i"
            class="text-center px-5 mb-4">
            <!-- style="border-right: 1px solid #eee; border-bottom: 1px solid #eee;"> -->
            <a :href="item.trackingUrl" target="_blank">
              <v-img lazy contain class="my-1 mx-auto" :title="item.title"
                :max-width="viewOptions.imgWidth"
                :height="viewOptions.imgHeight"
                :max-height="viewOptions.imgHeight"
                :src="`https://res.cloudinary.com/demo/image/fetch/e_trim/w_${viewOptions.imgWidth},h_${viewOptions.imgHeight},c_fit/${item.imageUrl}`"
              >
                <span
                  class="text-caption green white--text px-2 mx-1 mt-4 d-inline-block"
                  v-text="`New`"
                  v-if="(new Date() - item.createdAt) / (1000*60*60*24) < 30"
                ></span>
                <span
                  class="text-caption red white--text px-2 mx-1 mt-4 d-inline-block"
                  v-text="`${item.discount}% off`"
                  v-if="item.discount > 0"
                ></span>
              </v-img>
            </a>
            <router-link v-if="$store.state.search.view > 1"
              :to="{ name: 'Collection', params: { slug: item.brand.slugify() } }"
              class="text-subtitle-1 overflow-hidden mt-5 font-weight-bold text-capitalize
                black--text">
              {{ item.brand.toTitleCase() }}
            </router-link>
            <div class="text-subtitle-2 overflow-hidden mt-3" v-if="$store.state.search.view > 2">
              {{ item.title.toTitleCase() }}
            </div>
            <div class="text-h6 my-3" v-if="$store.state.search.view > 1">
              <span v-if="item.priceOrig" class="red--text">
                ${{ item.price }}
              </span>
              <strike v-if="item.priceOrig">
                ${{ item.priceOrig }}
              </strike>
              <span v-else>
                ${{ item.price }}
              </span>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <v-data-table
      v-else
      class="text-no-wrap my-n1 py-7"
      fixed-header
      mobile-breakpoint="1"
      must-sort
      :show-select="false"
      height="50%"
      :value="productsSelectedRows"
      :loading="$store.state.search.loading"
      :items="products"
      :headers="productsTableHeader"
      :search="$store.state.search.query"
      :items-per-page="10"
      :page.sync="productsCurrentPage"
      no-data-text="Select a collection to view products."
      no-results-text="Select a collection to view products"
      @pagination="paginationUpdated"
      :footer-props="{ itemsPerPageOptions: [5, 10, 20, 50, 100, 500] }">
      <!-- <template v-slot:top>
        <v-toolbar flat>
          <v-text-field v-model="$store.state.search.query" placeholder="Filter products"
            class="mt-3" clearable style="max-width: 300px;" dense
            append-icon="mdi-magnify" />
          <span class="ml-3 mb-2" :set="pagination = $store.state.search.pagination">
            {{ pagination.itemsLength }}{{ pagination.itemsLength >= 500 ? '+' : '' }}
            product{{ pagination.itemsLength != 1 ? 's' : '' }}
          </span>
          <v-spacer />
          <v-slider
            class="mt-2"
            v-model="$store.state.search.view"
            min="0"
            max="6"
            prepend-icon="mdi-format-list-bulleted-square"
            append-icon="mdi-view-grid"
            ticks="always"
            tick-size="4"
            @click:prepend="prevView()"
            @click:append="nextView()"
            style="max-width: 210px;"
          ></v-slider>
          <v-spacer />
        </v-toolbar>
      </template> -->
      <template v-slot:item.imageUrl="{ item }">
        <a :href="item.trackingUrl" target="_blank">
          <v-img lazy contain max-height="80" max-width="80" class="my-1"
            :src="`https://res.cloudinary.com/demo/image/fetch/e_trim/w_${viewOptions.imgWidth},h_${viewOptions.imgHeight},c_fit/${item.imageUrl}`"
          />
        </a>
      </template>
      <template v-slot:item.title="{ item }">
        <a :href="item.trackingUrl" target="_blank">{{ item.title }}</a>
      </template>
      <template v-slot:item.brand="{ item }">
        {{ item.brand.toTitleCase() }}
      </template>
      <template v-slot:item.category="{ item }">
        {{ item.category.toTitleCase() }}
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ new Date(item.createdAt).toLocaleDateString('en-AU') }}
      </template>
      <template v-slot:item.price="{ item }">
        <span v-if="item.priceOrig" class="red--text">
          ${{ item.price }}
        </span>
        <strike v-if="item.priceOrig">
          ${{ item.priceOrig }}
        </strike>
        <span v-else>
          ${{ item.price }}
        </span>
      </template>
      <template v-slot:item.discount="{ item }">
        {{ item.discount ? item.discount + '%' : '-' }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  // API,
  // graphqlOperation,
  Auth,
} from 'aws-amplify';
import * as S3 from 'aws-sdk/clients/s3'; // remove? "amplify extends aws sdk" (https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/) Maybe not, but use '@aws-sdk/client-s3' instead of importing the entire library.
import slugify from 'slugify';

export default {
  props: {
    // collection: Object,
    itemsPerPageProp: {
      type: Number,
      default: 48,
    },
    productsProp: Array,
  },
  created() {
    if (this.productsProp) {
      this.products = this.productsProp;
    } else {
      this.$store.state.search.loading = true;
      this.listProducts(this.collection);
      this.$store.state.search.loading = false;
    }

    if (this.itemsPerPageProp) {
      this.itemsPerPage = this.itemsPerPageProp;
    }
  },
  data() {
    return {
      itemsPerPage: 48,
      itemsPerPageOptions: [6, 12, 24, 48, 96, 200, 500],
      toggleFilter: false,
      viewOptionsMap: {
        0: {
          imgWidth: 120,
          imgHeight: 120,
        },
        1: {
          cols: 1,
          imgWidth: 160,
          imgHeight: 160,
        },
        2: {
          cols: 2,
          imgWidth: 320,
          imgHeight: 320,
        },
        3: {
          cols: 3,
          imgWidth: 480,
          imgHeight: 480,
        },
        4: {
          cols: 4,
          imgWidth: 640,
          imgHeight: 640,
        },
        5: {
          cols: 6,
          imgWidth: 960,
          imgHeight: 960,
        },
        6: {
          cols: 12,
          imgWidth: 1920,
          imgHeight: 1920,
        },
      },
      products: [],
      productsCurrentPage: 1,
      productsSelectedRows: [],
      productsTableHeader: [
        { text: 'Image', value: 'imageUrl' },
        { text: 'Title', value: 'title' },
        { text: 'Price', value: 'price' },
        { text: 'Discount', value: 'discount' },
        { text: 'Brand', value: 'brand' },
        { text: 'Created', value: 'createdAt' },
        { text: 'Category', value: 'category' },
        { text: '', value: 'actions', sortable: false },
      ],
    };
  },
  methods: {
    increaseItemsPerPage() {
      // this.itemsPerPage += 48; // the simple way
      const index = this.itemsPerPageOptions.indexOf(this.itemsPerPage);
      if (index !== -1) {
        this.itemsPerPage = this.itemsPerPageOptions[index + 1];
      }
    },
    async listProducts(expandedCollection) {
      console.log('listProducts (deprecated)');

      if (!this.collection.store || this.collection.store === '-') {
        // no store = no S3 file to query
        console.log('no store');
        return;
      }

      if (expandedCollection) {
        this.$store.state.search.query = null; // reset
        this.products = [];

        // no store = no S3 file to query
        if (expandedCollection.store && expandedCollection.store === '-') {
          let credentials = {};
          try {
            credentials = await Auth.currentCredentials();
            // console.log('user', credentials);
          } catch (e) {
            // await Auth.signIn('guest', 'password'); // guest user
            // credentials = await Auth.currentCredentials();
            // console.log('guest', credentials);
            console.log(e);
          }

          const collection = expandedCollection;
          const s3 = new S3({
            // region: 'us-east-1',
            credentials: Auth.essentialCredentials(credentials),
          });

          let whereClause = '';
          if (collection.brand && collection.brand !== '-') {
            whereClause = `WHERE brand = '${collection.brand.replace('\'', '\'\'')}'`;
          }
          if (collection.category && collection.category !== '-') {
            if (whereClause) {
              whereClause += ` AND category = '${collection.category.replace('\'', '\'\'')}'`;
            } else {
              whereClause += ` WHERE category = '${collection.category.replace('\'', '\'\'')}'`;
            }
          }

          const params = {
            Bucket: 'shopscoutstorage104233-prod', // change bucket region depending on country
            Key: `feeds/${slugify(collection.store, { lower: true })}.parquet`,
            ExpressionType: 'SQL',
            Expression: `SELECT title, brand, imageUrl, price, category, trackingUrl, priceOrig, createdAt FROM S3Object ${whereClause} LIMIT 500`,
            InputSerialization: {
              Parquet: {},
            },
            OutputSerialization: {
              JSON: {
                RecordDelimiter: ',\n',
              },
            },
          };

          try {
            const selectObjectContentResponse = await s3.selectObjectContent(params).promise();

            let recordsJSON = '[';
            // eslint-disable-next-line
            for await (const event of selectObjectContentResponse.Payload) {
              if (event.Records) {
                recordsJSON += event.Records.Payload.toString();
              }
            }
            recordsJSON = recordsJSON.slice(0, -2); // remove trailing comma for last item
            recordsJSON += ']';

            try {
              this.products = await JSON.parse(recordsJSON);
              for (let i = 0; i < this.products.length; i += 1) {
                this.products[i].discount = this.products[i].priceOrig
                  ? 100 - Math.round((this.products[i].price / this.products[i].priceOrig) * 100)
                  : 0;
              }
            } catch (e) {
              console.log(e);
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    nextView() {
      this.$store.state.search.view = Math.max(0, Math.min(this.$store.state.search.view + 1, 6));
    },
    prevView() {
      this.$store.state.search.view = Math.max(0, Math.min(this.$store.state.search.view - 1, 6));
    },
    paginationUpdated(data) {
      this.$store.state.search.pagination = data;
    },
  },
  watch: {
    collection(collection) {
      if (collection && collection.store !== '-') this.listProducts(collection);
    },
    productsProp(val) {
      this.products = val;
    },
  },
  computed: {
    collection() {
      return this.$store.state.collection;
    },
    viewOptions() {
      return this.viewOptionsMap[this.$store.state.search.view];
    },
  },
};
</script>
