import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Collection from '../views/Collection.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    meta: {
      title: 'Brand',
    },
    path: '/brands/:slug',
    name: 'BrandCollection',
    component: Collection,
    props: (route) => ({
      default: true,
      slug: route.params.slug,
      collectionProp: route.params.collectionProp,
      type: 'brand',
    }),
  },
  {
    meta: {
      title: 'Store',
    },
    path: '/stores/:slug',
    name: 'StoreCollection',
    component: Collection,
    props: (route) => ({
      default: true,
      slug: route.params.slug,
      collectionProp: route.params.collectionProp,
      type: 'store',
    }),
  },
  {
    meta: {
      title: 'Collection',
    },
    path: '/:slug',
    name: 'Collection',
    component: Collection,
    props: true,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
