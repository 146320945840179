import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';

export const collectionBySlug = async (slug, type) => {
  const params = { slug };
  if (type) params.type = { eq: type };
  const query = 'collectionsBySlugType';
  const response = await API.graphql(graphqlOperation(queries[query], params));
  if (response.data[query].items.length > 0) {
    return response.data[query].items[0];
  }
  return null;
};

// @deprecated use getCategories
export const categoriesByCollection = async (collection) => {
  const typeField = collection.type === 'brand' ? collection.type : 'store';
  const query = `${typeField}CardinalityCategoryStatus`;
  const params = {
    slug: collection.slug,
    limit: 500,
  };
  params[typeField] = collection[typeField];
  params.cardinalityCategoryStatus = { beginsWith: { cardinality: 3 } };
  const response = await API.graphql(graphqlOperation(queries[query], params));
  return response.data[query].items;
};

export const getCategories = async (store, brand, mode) => {
  let query = 'ERR';
  const params = {
    limit: 500,
  };
  const modeToStatus = [null, 2, 1];

  if (store && brand && store !== '-' && brand !== '-') {
    query = 'brandCardinalityStoreStatus';
    params.brand = brand;
    params.cardinalityStoreStatus = {
      beginsWith: {
        cardinality: 3,
        store,
        status: modeToStatus[mode],
      },
    };
  } else if (store && store !== '-') {
    query = 'storeCardinalityStatus';
    params.store = store;
    params.cardinalityStatus = {
      beginsWith: {
        cardinality: 3,
        status: modeToStatus[mode],
      },
    };
  } else if (brand && brand !== '-') {
    query = 'brandCardinalityStatus';
    params.brand = brand;
    params.cardinalityStatus = {
      beginsWith: {
        cardinality: 3,
        status: modeToStatus[mode],
      },
    };
  }

  const response = await API.graphql(graphqlOperation(queries[query], params));
  const categories = response.data[query].items;

  // sort by category title
  categories.sort((a, b) => a.category.localeCompare(b.category));

  return categories;
};

export const getStores = async (brand, category, mode) => {
  let query = 'ERR';
  const params = {
    limit: 500,
  };
  const modeToStatus = [null, 2, 1];

  if (category && brand && category !== '-' && brand !== '-') {
    query = 'brandCardinalityCategoryStatus';
    params.brand = brand;
    params.cardinalityCategoryStatus = {
      beginsWith: {
        cardinality: 2,
        category,
        status: modeToStatus[mode],
      },
    };
  } else if (category && category !== '-') {
    query = 'categoryCardinalityStatus';
    params.category = category;
    params.cardinalityStatus = {
      beginsWith: {
        cardinality: 2,
        status: modeToStatus[mode],
      },
    };
  } else if (brand && brand !== '-') {
    query = 'brandCardinalityStatus';
    params.brand = brand;
    params.cardinalityStatus = {
      beginsWith: {
        cardinality: 2,
        status: modeToStatus[mode],
      },
    };
  }

  const response = await API.graphql(graphqlOperation(queries[query], params));
  const stores = response.data[query].items;

  stores.sort((a, b) => b.total - a.total);

  return stores;
};

export const getBrands = async (store, category, mode) => {
  let query = 'ERR';
  const params = {
    limit: 500,
  };
  const modeToStatus = [null, 2, 1];

  if (category && store && category !== '-' && store !== '-') {
    query = 'storeCardinalityCategoryStatus';
    params.store = store;
    params.cardinalityCategoryStatus = {
      beginsWith: {
        cardinality: 3,
        category,
        status: modeToStatus[mode],
      },
    };
  } else if (category && category !== '-') {
    query = 'categoryCardinalityStatus';
    params.category = category;
    params.cardinalityStatus = {
      beginsWith: {
        cardinality: 3,
        status: modeToStatus[mode],
      },
    };
  } else if (store && store !== '-') {
    query = 'storeCardinalityStatus';
    params.store = store;
    params.cardinalityStatus = {
      beginsWith: {
        cardinality: 3,
        status: modeToStatus[mode],
      },
    };
  }

  const response = await API.graphql(graphqlOperation(queries[query], params));
  const brands = response.data[query].items;

  brands.sort((a, b) => a.brand.localeCompare(b.brand));

  return brands;
};

export default {};
