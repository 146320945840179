<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <v-list-item to="/" key="home" link>
          <v-list-item-action><v-icon>mdi-home-variant</v-icon></v-list-item-action>
          <v-list-item-content><v-list-item-title>Home</v-list-item-title></v-list-item-content>
        </v-list-item>
        <template v-for="listType in ['brands', 'stores']">
          <template v-if="$store.state.userDetails && listType in $store.state.userDetails">
            <v-row align="center" :key="`heading-${listType}`">
              <v-col class="pl-7 pb-2 pt-5 text-subtitle-1">
                Recent {{ listType === 'categorys' ? 'Categories' : listType.toLowerCase() }}
              </v-col>
            </v-row>
            <v-list-item v-for="item in $store.state.userDetails[listType].slice(0, 15).sort()"
              :key="`${listType}-${item}`" link
              :to="{ name: 'Collection', params: { slug: slug(item) } }">
              <v-list-item-action>
                <v-icon color="black">
                  {{ listType === 'brands' ? `mdi-alpha-${item[0]}-circle`
                    : chipIcons[listType.slice(0, -1)] }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.toTitleCase() }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-row :key="`no-data-${listType}`" v-else>
              <v-col class="mx-4">
                <small class="grey--text lighten-5">
                  None yet
                </small>
              </v-col>
            </v-row> -->
          </template>
        </template>
        <v-row align="center">
          <v-col class="pl-7 pb-2 pt-5 text-subtitle-1">
            New arrivals & sales
          </v-col>
        </v-row>
        <v-list-item v-for="item in featuredBrands"
          :key="`featuredBrands-${item.id}`" link
          :to="{ name: 'Collection', params: { slug: slug(item.title) } }">
          <v-list-item-action>
            <v-icon color="black">
              {{ `mdi-alpha-${item.title[0]}-circle` }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title.toTitleCase() }}</v-list-item-title>
          </v-list-item-content>
          <!-- <v-badge inline
            :color="item.totalSale ? 'red' : 'green'"
            :content="item.totalSale ? `${item.discount}% off` : `${item.totalNew} new`">
          </v-badge> -->
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      dark
      class="align-center justify-end"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-toolbar-title name="navbar"
        style="width: 300px"
        class="ml-0 pl-4 hidden-xs-only"
      >
        <v-btn href="/" text dark>Shopscout</v-btn>
      </v-toolbar-title>

      <v-spacer class="hidden-xs-only" />

      <v-autocomplete
        :search-input.sync="searchInput"
        :items="searchItems"
        placeholder="Search brands and stores"
        outlined
        dense
        item-text="name"
        item-value="route"
        class="mt-6"
        append-icon="mdi-magnify"
        @change="search"
        :loading="autoSuggestionsLoading"
        hide-no-data
      >
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-icon>
              <v-icon>{{ chipIcons[data.item.group.toLowerCase()] }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>

      <v-spacer class="hidden-sm-and-down" />

      <template v-if="user">
        <span v-if="user.attributes">
          {{ user.attributes.email }}
        </span>
        <v-btn v-on:click="signOut" title="Sign out" icon>
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
import * as queries from '@/graphql/queries';
import { AmplifyEventBus } from 'aws-amplify-vue';
import {
  API,
  graphqlOperation,
  Auth,
} from 'aws-amplify';
import slugify from 'slugify';

export default {
  props: {
    source: String,
  },
  data() {
    return {
      chipIcons: {
        store: 'mdi-cart-outline',
        collection: 'mdi-tag-multiple-outline',
        product: 'mdi-circle-multiple-outline',
        category: 'mdi-tag-multiple-outline',
        brand: 'mdi-shield-star-outline',
      },
      searchInput: null,
      drawer: false,
      user: null,
      searchItems: [],
      autoSuggestionsTimer: 0,
      autoSuggestionsLoading: false,
    };
  },
  methods: {
    signOut() {
      this.$Amplify.Auth.signOut()
        .then(() => AmplifyEventBus.$emit('authState', 'signedOut'))
        .catch((e) => console.error(e));
    },
    async updateAutoSuggestions() {
      this.autoSuggestionsLoading = true;
      const query = 'collectionsByCardinalityTitle';
      const params = { cardinality: 1 };

      if (this.searchInput) {
        params.title = { beginsWith: this.searchInput.toLowerCase() };
      }

      const response = await API.graphql(graphqlOperation(
        queries[query],
        params,
      ));
      this.searchItems = []; // reset
      const collections = response.data[query].items;
      const len = collections.length;
      let i = 0;
      while (i < len) {
        const item = collections[i];
        this.searchItems.push({
          name: item.title.toTitleCase(),
          route: {
            name: item.type === 'brand' ? 'Collection' : `${item.type.toTitleCase()}Collection`,
            params: {
              slug: slugify(item.title, { lower: true }),
              type: item.type,
              collectionProp: item,
            },
          },
          group: collections[i].type,
        });
        i += 1;
      }
      this.autoSuggestionsLoading = false;
    },
    search(item) {
      this.$store.state.collection = item.params.collectionProp;
      this.$router.push(item).catch(() => {});
    },
    slug(text) {
      return text ? slugify(text, { lower: true }) : '/';
    },
  },
  async mounted() {
    try {
      this.user = await Auth.currentAuthenticatedUser();
    } catch (e) {
      // console.log(e);
    }

    // featured brands
    // this.featuredBrands = [...this.$store.state.featuredBrands]
    //   .sort(() => 0.5 - Math.random())
    //   .slice(0, 20)
    //   .sort((a, b) => (a.title > b.title ? 1 : -1));

    // this.updateAutoSuggestions();
  },
  computed: {
    featuredBrands() {
      const featuredBrands = [...this.$store.state.featuredBrands]
        .sort(() => 0.5 - Math.random())
        .slice(0, 20)
        .sort((a, b) => (a.title > b.title ? 1 : -1));
      return featuredBrands;
    },
  },
  watch: {
    searchInput: {
      handler(a, b) {
        clearTimeout(this.autoSuggestionsTimer); // cancel pending call
        this.autoSuggestionsTimer = setTimeout(() => {
          if (String(a).trim() !== String(b).trim()) {
            this.updateAutoSuggestions();
          }
        }, 200); // delay new call
      },
    },
  },
};
</script>
