<template>
  <v-toolbar flat class="mx-n3 mt-5 sticky">
    <v-btn-toggle mandatory dense class="mx-3 mt-n3"
      v-model="$store.state.search.mode">
      <v-btn v-for="mode in ['All', 'New', 'Sale']" :key="mode" outlined>{{ mode }}</v-btn>
    </v-btn-toggle>
    <v-spacer />
    <div class="ml-3 mb-2 hidden-sm-and-down body-2 grey--text"
      :set="pagination = $store.state.search.pagination">
      <!-- <v-btn @click="toggleFilter = !toggleFilter" icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->
      <v-text-field v-model="$store.state.search.query"
        class="mt-3 hidden-sm-and-down" clearable style="max-width: 240px;"
        :hint="hideQueryHint ? '' :
          `${pagination.itemsLength}${ pagination.itemsLength >= 500 ? '+' : '' }
          product${ pagination.itemsLength != 1 ? 's' : '' }`"
        persistent-hint prepend-inner-icon="mdi-magnify" />
    </div>
    <v-spacer class="hidden-sm-and-down" v-if="$store.state.brands.length > 1" />
    <v-autocomplete label="Brands" dense outlined class="mt-3" clearable
      style="max-width: 240px" v-model="$store.state.search.brand"
      :items="$store.state.brands.map((x) => ({
          value: x.brand, text: x.brand.toTitleCase() }))"
      v-if="$store.state.collection.type == 'store' && $store.state.brands.length > 1" />
    <v-spacer class="hidden-sm-and-down" />
    <v-slider
      class="mt-2"
      v-model="$store.state.search.view"
      min="0"
      max="6"
      prepend-icon="mdi-format-list-bulleted-square"
      append-icon="mdi-view-grid"
      ticks="always"
      tick-size="4"
      @click:prepend="prevView()"
      @click:append="nextView()"
      style="max-width: 210px;"
    ></v-slider>
    <v-spacer />
    <v-btn v-on:click="$store.state.search.sortDesc = !$store.state.search.sortDesc" icon
      class="mt-n2">
      <v-icon>
        {{ $store.state.search.sortDesc ? 'mdi-sort-descending' : 'mdi-sort-ascending' }}
      </v-icon>
    </v-btn>
    <v-select placeholder="Sort" :items="sortOptions" dense class="mt-4"
      v-model="$store.state.search.sortBy" style="max-width: 240px;" outlined
      label="Sort by" />
  </v-toolbar>
</template>
<script>
export default {
  props: ['hideQueryHint'],
  data() {
    return {
      sortOptions: [
        { text: 'Image', value: 'imageUrl' },
        { text: 'Title', value: 'title' },
        { text: 'Price', value: 'price' },
        { text: 'Discount', value: 'discount' },
        { text: 'Brand', value: 'brand' },
        { text: 'Created', value: 'createdAt' },
        { text: 'Category', value: 'category' },
        { text: '', value: 'actions', sortable: false },
      ],
    };
  },
  methods: {
    nextView() {
      this.$store.state.search.view = Math.max(0, Math.min(this.$store.state.search.view + 1, 6));
    },
    prevView() {
      this.$store.state.search.view = Math.max(0, Math.min(this.$store.state.search.view - 1, 6));
    },
  },
};
</script>

<style>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  z-index: 1;
}
</style>
