var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mx-5 my-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h3"},[_vm._v("Welcome")])]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Keep track of all "),_c('strong',[_vm._v("sales and new arrivals")]),_vm._v(" from the stores and brands you follow. Type a brand, store, or category in the search box above. ")])],1),(_vm.$store.state.userDetails)?_c('v-row',{staticClass:"mx-5 my-3"},_vm._l((['brands','stores']),function(listType){return _c('v-col',{key:listType,attrs:{"cols":"12"}},[_c('v-row',{key:("heading-" + listType),attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-overline"},[_vm._v(" Recent "+_vm._s(listType === 'categorys' ? 'Collections' : listType.toTitleCase())+" ")])],1),(listType in _vm.$store.state.userDetails &&
          _vm.$store.state.userDetails[listType].length == 0)?_c('v-row',{key:("no-data-" + listType)},[_c('v-col',{staticClass:"mx-4"},[_c('small',{staticClass:"font-italic font-weight-thin"},[_vm._v(" "+_vm._s(listType.toTitleCase())+" you visit will show here ")])])],1):_vm._e(),(listType in _vm.$store.state.userDetails)?_c('v-row',_vm._l((_vm.$store.state.userDetails[listType].slice(0, 20).sort()),function(item){return _c('v-col',{key:(listType + "-" + item),attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-card',[_c('v-btn',{staticClass:"px-5 py-8 ma-0 justify-center black white--text",attrs:{"text":"","block":"","to":{
                name: 'Collection',
                params: { slug: item.slugify() },
              }}},[_vm._v(" "+_vm._s(item.toTitleCase())+" ")])],1)],1)}),1):_vm._e()],1)}),1):_vm._e(),_c('v-divider'),_c('v-row',{staticClass:"mx-5 my-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-overline"},[_vm._v(" Featured brands ")])],1),_c('v-row',{attrs:{"align":"center"}},_vm._l((_vm.$store.state.featuredBrands),function(item){return _c('v-col',{key:("featuredBrand-" + (item.id)),attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-card',[_c('v-btn',{staticClass:"px-5 py-8 ma-0 justify-center black white--text overflow-hidden",attrs:{"text":"","block":"","to":{
                name: 'Collection',
                params: { slug: item.title.slugify(), type: 'brand' },
              }}},[_vm._v(" "+_vm._s(item.title.toTitleCase())+" ")])],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }