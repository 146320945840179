<template>
  <v-app>
    <div v-if="!signedIn" class="text-center mt-5 pt-5">
      <amplify-authenticator :authConfig="{ signInConfig: { isSignUpDisplayed: false } }" />
    </div>
    <div v-if="signedIn">
      <v-main>
        <Navbar/>
        <router-view />
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import { Auth } from 'aws-amplify';
import { AmplifyEventBus } from 'aws-amplify-vue';

export default {
  name: 'App',
  components: {
    Navbar,
  },
  data() {
    return {
      signedIn: true,
    };
  },
  watch: {
    $route(to) {
      if ('meta' in to) {
        document.title = to.meta.title || 'Shopscout';
      }
    },
  },
  async created() {
    this.$store.commit('fetchUserDetails');
    this.$store.commit('fetchFeaturedBrands');
  },
  async beforeCreate() {
    // const requiredGroups = ['us-east-1_aFjC2004X_Facebook', 'admin']
    // Auth.federatedSignIn();
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        this.signedIn = true;
      }
      // if (user.signInUserSession.idToken.payload['cognito:groups'].includes('admin')) {
      //   this.signedIn = true;
      // } else {
      //   this.$Amplify.Auth.signOut()
      //     .then(() => AmplifyEventBus.$emit('authState', 'signedOut'))
      //     .catch((e) => console.error(e));
      // }
    } catch (err) {
      // this.signedIn = false;
      // console.log('not signed in');
      // Auth.federatedSignIn().then((data) => {
      //   console.log('foo', data);
      //   this.signedIn = true;
      // }).catch(console.log);

      // A GUEST USER WILL BE NEEDED FOR "S3 SELECT"
      // await Auth.signIn('guest', 'password'); // guest user
      // this.signedIn = true;
    }
    AmplifyEventBus.$on('authState', async (info) => {
      if (info === 'signedIn') {
        const user = await Auth.currentAuthenticatedUser();
        console.log(user);
        this.signedIn = true;
        // if (user.signInUserSession.idToken.payload['cognito:groups'].includes('admin')) {
        //   this.signedIn = true;
        // } else {
        //   this.$Amplify.Auth.signOut()
        //     .then(() => AmplifyEventBus.$emit('authState', 'signedOut'))
        //     .catch((e) => console.error(e));
        // }
      } else {
        this.signedIn = false;
      }
    });
  },
};
</script>

<style>
tbody tr :hover {
  cursor: pointer;
}
a {
    text-decoration: none;
}
</style>
