export const buildTreeview = (node, path, depth) => {
  if (depth < path.length) {
    const currentItemTitle = path[depth].trim();
    let cat = node.children.find((x) => x.name === currentItemTitle);
    if (!cat) {
      cat = { id: path.slice(0, depth + 1).join(' > '), name: currentItemTitle, children: [] };
      node.children.push(cat);
    }
    buildTreeview(cat, path, depth + 1);
  }
};

export default {};
