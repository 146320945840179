<template>
  <v-container fluid class="narrow pt-7" v-if="$store.state.collection">
    <CollectionToolbar :collection="$store.state.collection" class="mb-3" />
    <SearchToolbar :hide-query-hint="isAggregate" />
    <Stores v-if="isAggregate" :stores="stores" />
    <BrowseCollection v-else :collection="$store.state.collection"
      :category-collections-prop="categoryCollections" />
  </v-container>
</template>

<script>
import CollectionToolbar from '@/components/CollectionToolbar.vue';
import BrowseCollection from '@/components/BrowseCollection.vue';
import SearchToolbar from '@/components/SearchToolbar.vue';
import Stores from '@/components/Stores.vue';
import {
  collectionBySlug,
  categoriesByCollection,
  getStores,
} from '@/helpers/api';

export default {
  props: ['slug', 'type', 'collectionProp'],
  components: {
    CollectionToolbar,
    BrowseCollection,
    SearchToolbar,
    Stores,
  },
  data() {
    return {
      collection: null,
      categoryCollections: [],
      isAggregate: true,
      stores: [],
      categories: [],
    };
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      if (this.collectionProp) {
        this.initByCollectionProp();
      } else if (!this.collection) {
        await this.initBySlug();
      }
      this.initExtras();
    },
    async initByCollectionProp() {
      this.collection = this.collectionProp;
      this.$store.commit('setCollection', this.collection);
    },
    async initBySlug() {
      this.collection = await collectionBySlug(this.slug, this.type);
      this.$store.commit('setCollection', this.collection);
    },
    async initExtras() {
      if (!this.collection) {
        console.log('initExtras: no collection');
        return;
      }

      // if store
      if (this.collection.type === 'store') {
        this.isAggregate = false;
        this.categoryCollections = await categoriesByCollection(this.collection);
        this.$store.state.search.view = 2;
      // otherwise (brand or category)
      } else {
        // get all stores (assuming brand)
        const stores = await getStores(this.$store.state.collection.brand);
        this.isAggregate = stores.length > 1;

        if (stores.length > 0) {
          // test
          this.categories = await categoriesByCollection(this.collection);

          if (this.isAggregate) {
            // view
            this.$store.state.search.view = 1;

            for (let i = 0; i < stores.length; i += 1) {
              // categories
              stores[i].categories = this.categories.filter(
                (x) => x.store === stores[i].store,
              );
            }

            // stores
            this.stores = stores;
          } else {
            this.$store.state.search.view = 2;
            if (this.collection.store === '-') {
              this.$set(this.collection, 'store', stores[0].store);
            }
            this.categoryCollections = await categoriesByCollection(this.collection);
          }
        }
      }
    },
  },
  watch: {
    async slug() {
      if (!this.collectionProp) this.initBySlug();
    },
    async collectionProp() {
      this.initByCollectionProp();
    },
    '$store.state.search.sortBy': function (val) {
      this.$store.state.search.sortDesc = ['discount', 'createdAt'].includes(val);
      this.$store.state.search.currentPage = 1;
    },
    '$store.state.collection': function () {
      document.title = `${this.$store.state.collection.title.toTitleCase()}
        - Sales & New Arrivals - Shopscout Australia`;
      this.$store.state.search.mode = 0;
      this.$store.commit('pushCollectionHistory', this.$store.state.collection);
      this.init();
    },
  },
};
</script>
