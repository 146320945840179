<template>
  <v-data-iterator
    :items="products"
    v-if="$store.state.search.view > 0"
    :items-per-page="itemsPerPage"
    class="white"
    :footer-props="{
      itemsPerPageOptions,
      disableItemsPerPage: false,
      itemsPerPageText: '',
      // showFirstLastPage: true,
      pagination: $store.state.search.pagination,
    }"
    :sort-by.sync="$store.state.search.sortBy"
    :sort-desc="$store.state.search.sortDesc"
    :search="$store.state.search.query"
    :loading="$store.state.search.loading"
    :page.sync="currentPage"
    @pagination="paginationUpdated"
    no-data-text=""
    loading-text=""
    @update:sort-by="currentPage = 1"
    @update:sort-desc="currentPage = 1"
    >
  >
    <template v-slot:footer.page-text="{  pageStart, pageStop, itemsLength }"
      disable-items-per-page>
      {{ pageStart }}
      -
      {{ pageStop }}
      of
      {{ itemsLength }}
      <!-- <v-btn color="primary" small @click="increaseItemsPerPage()"
        v-if="pageStop < itemsLength" class="ml-9">
        Load more
      </v-btn> -->
    </template>
    <template v-slot:default="{ items }">
      <v-row class="mx-1 mt-5">
        <v-col :cols="viewOptions.cols" v-for="(item, i) in items" :key="i"
          class="text-center">
          <!-- style="border-right: 1px solid #eee; border-bottom: 1px solid #eee;"> -->
          <a :href="item.trackingUrl" target="_blank">
            <v-img lazy contain class="my-1 mx-auto" :title="item.title"
              :max-width="viewOptions.imgWidth"
              :height="viewOptions.imgHeight"
              :max-height="viewOptions.imgHeight"
              :src="`https://res.cloudinary.com/demo/image/fetch/e_trim/w_${viewOptions.imgWidth},h_${viewOptions.imgHeight},c_fit/${item.imageUrl}`"
            />
          </a>
          <router-link v-if="$store.state.search.view > 1"
            :to="{ name: 'Collection', params: { slug: item.brand.slugify() } }"
            class="text-subtitle-1 overflow-hidden mt-5 font-weight-bold text-capitalize
              black--text">
            {{ item.brand.toTitleCase() }}
          </router-link>
          <div class="text-subtitle-2 overflow-hidden mt-3" v-if="$store.state.search.view > 2">
            {{ item.title.toTitleCase() }}
          </div>
          <div class="text-subtitle-1 my-3" v-if="$store.state.search.view > 0">
            <span v-if="item.priceOrig" class="red--text">
              ${{ item.price }}
            </span>
            <strike v-if="item.priceOrig">
              ${{ item.priceOrig }}
            </strike>
            <span v-else>
              ${{ item.price }}
            </span>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
export default {
  props: ['products'],
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 12,
      itemsPerPageOptions: [6, 12, 24, 48, 96, 200, 500],
    };
  },
  computed: {
    viewOptions() {
      let cols = this.$store.state.search.view;
      switch (this.$store.state.search.view) {
        case 0:
          break;
        case 5:
          cols = 6;
          break;
        case 6:
          cols = 12;
          break;
        default:
      }

      const dimension = this.$store.state.search.view > 0 ? cols * 160 : 120;

      return {
        cols,
        imgWidth: dimension,
        imgHeight: dimension,
      };
    },
  },
  methods: {
    paginationUpdated(data) {
      this.$store.state.search.pagination = data;
    },
  },
  watch: {
    products() {
      this.currentPage = 1;
    },
  },
};
</script>
