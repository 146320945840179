<template>
  <v-toolbar flat>
    <v-avatar color="black" size="62">
      <span class="white--text headline">
        {{ collection.title ? collection.title[0].toUpperCase() : '-' }}
      </span>
    </v-avatar>
    <h1 class="text-h5 ml-5 my-9">
      {{ collection.title.toTitleCase() }}
    </h1>
    <!-- <v-autocomplete placeholder="Brands" class="mt-6 ml-6" clearable
        style="max-width: 240px" rounded solo dense /> -->
  </v-toolbar>
</template>

<script>
export default {
  props: ['collection'],
};
</script>
