// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:af2631c7-24a8-44f1-86b2-22fa20178133",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_aFjC2004X",
    "aws_user_pools_web_client_id": "5mp6gme7jsgcep56npj6a0r00d",
    "oauth": {
        "domain": "shopscoutcore8f1f5050-8f1f5050-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost/sign-in/,http://localhost/sign-in/",
        "redirectSignOut": "http://localhost/sign-out/,http://localhost/sign-out/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://fvjdsvjapjbzvhhozepwaalaw4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fxjp5guiyjcpnpopinvmcwnxm4",
    "aws_user_files_s3_bucket": "shopscoutstorage104233-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
