<!-- @todo rename to `StorePanel.vue` -->
<template>
  <v-card class="mt-7 mx-4" v-if="hasMode(this.$store.state.search.mode)">
    <v-card-title class="justify-space-between body-1 px-6">
      <router-link :to="{ name: 'StoreCollection', params: {
          slug: store.store.slugify(),
        } }" @click="$store.state.search.brand = $store.state.collection.brand"
        class="text--secondary text-h6">
        {{ store.store.toTitleCase() }}
      </router-link>
      <!-- <span class="text-subtitle-1">{{ store.store.toTitleCase() }}</span> -->
      <v-chip-group mandatory column v-model="activeCategory">
        <v-chip small outlined @click.stop value="-" :title="`${modeTotal(store)} products`">
          All
        </v-chip>

        <v-chip
          v-for="item in store.categories.slice(0, 10)" :key="item.id"
          small outlined @click.stop :value="item.category" :title="`${modeTotal(item)} products`">
          {{ item.category.split('>').slice(-1)[0].trim().toTitleCase() }}
          <!-- <small class="ml-1 text--disabled">{{ item.total }}</small> -->
        </v-chip>
        <v-chip
          v-if="store.categories.length > 12"
          color="grey lighten-5" small @click.stop>
          more+
        </v-chip>
      </v-chip-group>
      <span class="text--disabled body-2">{{ store.total }} products</span>
    </v-card-title>
    <v-card-text>
      <ProductsDataIterator :products="products" />
    </v-card-text>
  </v-card>
</template>

<script>
import ProductsDataIterator from '@/components/ProductsDataIterator.vue';
import { getProducts } from '@/helpers/s3';
import { getCategories } from '@/helpers/api';

export default {
  props: ['store'],
  components: {
    ProductsDataIterator,
  },
  data() {
    return {
      activeCategory: {},
      products: [],
    };
  },
  created() {
    this.initProducts();
  },
  methods: {
    hasMode(mode) {
      // # 0 = none, 1 = on sale, 2 = new, 3 = both
      let answer = true;
      switch (mode) {
        case 1: // 1 = new
          answer = ['2', '3'].includes(this.store.status);
          break;
        case 2: // 2 = sale
          answer = ['1', '3'].includes(this.store.status);
          break;
        default: // 0 = all
          break;
      }
      return answer;
    },
    async initProducts(category) {
      const cat = category || '-';
      await getProducts(this.store.store, cat, this.store.brand, this.$store.state.search.mode)
        .then((data) => {
          this.products = data;
        })
        .catch(console.log);
    },
    modeTotal(collection) {
      switch (this.$store.state.search.mode) {
        case 1: // new
          return collection.totalNew;
        case 2: // sale
          return collection.totalSale;
        default:
          return collection.totalNew;
      }
    },
  },
  watch: {
    async activeCategory(val) {
      this.initProducts(val);
    },
    '$store.state.search.mode': async function (val) {
      if (this.hasMode(val)) { // only update the content if the store is shown (mode = store mode)
        this.initProducts();
        this.activeCategory = '-';
        this.store.categories = await getCategories(
          this.store.store,
          this.store.brand,
          this.$store.state.search.mode,
        );
      }
    },
  },
};
</script>
