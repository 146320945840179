import * as queries from '@/graphql/queries';
import Vue from 'vue';
import Vuex from 'vuex';
import slugify from 'slugify';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import awsExports from './aws-exports';

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(AmplifyPlugin, AmplifyModules);

Vue.config.warnHandler = (msg, vm, trace) => {
  if (msg !== 'Invalid prop: custom validator check failed for prop "selectionType".') {
    const hasConsole = typeof console !== 'undefined';
    if (hasConsole && !Vue.config.silent) {
      console.error(`[Vue warn]: ${msg}${trace}`);
    }
  }
};

Amplify.configure({ ...awsExports, ssr: true });

// @todo move to separate file
// @todo consider using mixins instead
// eslint-disable-next-line
String.prototype.toTitleCase = function() {
  return this.replace(/(^|\s)\S/g, ((t) => t.toUpperCase()));
};
// eslint-disable-next-line
String.prototype.slugify = function() {
  return this ? slugify(this, { lower: true }) : '/';
};

const store = new Vuex.Store({
  state: {
    userDetails: {},
    featuredBrands: [],
    collection: null,
    search: { // for it to be reactive it needs to be defined here
      view: 2,
      mode: 0,
      sortDesc: false,
      pagination: {},
      loading: false,
      category: null,
      brand: null,
    },
    brands: [], // shown in searchtoolbar, fetched in BrowseCollection (refactor?)
  },
  mutations: {
    setCollection: async (state, val) => {
      try {
        state.collection = val;
      } catch (e) {
        console.log(e);
      }
    },
    setBrand: async (state, val) => {
      try {
        state.brand = val;
      } catch (e) {
        console.log(e);
      }
    },
    setCategory: async (state, val) => {
      try {
        state.category = val;
      } catch (e) {
        console.log(e);
      }
    },
    setMode: async (state, val) => {
      try {
        state.mode = val;
      } catch (e) {
        console.log(e);
      }
    },
    setSearch: async (state, val) => {
      try {
        state.search = val;
      } catch (e) {
        console.log(e);
      }
    },
    fetchUserDetails: async (state) => {
      try {
        state.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      } catch (e) {
        console.log(e);
      }
    },
    fetchFeaturedBrands: async (state) => {
      try {
        const query = 'categoryCardinalityStatus';
        const params = {
          limit: 48, // because it's divisible by 12, 6, 4, 3, and 2
          category: '-',
          // cardinalityStatus: {
          //   eq: {
          //     cardinality: 2,
          //     status: 1,
          //   },
          // },
          cardinalityStatus: {
            between: [
              { cardinality: 2, status: 1 },
              { cardinality: 2, status: 4 },
            ],
          },
        };
        const response = await API.graphql(
          graphqlOperation(queries[query], params),
        );
        if (response.data[query].items.length > 0) {
          state.featuredBrands = response.data[query].items.sort(
            // (a, b) => (a.title > b.title ? 1 : -1), // sort by title
            (a, b) => (a.total < b.total ? 1 : -1), // sort by count
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
    pushCollectionHistory: async (state, collection) => {
      if (collection && 'type' in collection) {
        try {
          const typeListKey = `${collection.type}s`; // refactor!
          if (!('userDetails' in state) || state.userDetails === null
            || typeof state.userDetails !== 'object') {
            state.userDetails = {};
          } else {
            // console.log(e);
          }
          if (!(typeListKey in state.userDetails)) state.userDetails[typeListKey] = [];
          state.userDetails[typeListKey].unshift(collection.title); // add to front
          state.userDetails[typeListKey] = [...new Set(state.userDetails[typeListKey])]; // unique
          localStorage.setItem('userDetails', JSON.stringify(state.userDetails)); // cache locally
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
